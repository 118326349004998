import React from 'react'

import { Link } from 'react-router-dom';

const Laminate = () => {
  return (
    <div className='catalogue'>
      <div className="container">
        <h1>Laminate</h1>
        <div className="items">
          <div className="categories">
            <Link to="/catalogue/laminate/mood"><div className='category'>Mood</div></Link>
            <Link to="/catalogue/laminate/effect"><div className='category'>Effect 8mm</div></Link>
            <Link to="/catalogue/laminate/herringbone"><div className='category'>Herringbone</div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Laminate