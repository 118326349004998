import React from 'react'

const Henley = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Henley</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-corn.webp" alt="Corn" />
                    <p>Corn</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-anthracite.webp" alt="Anthracite" />
                    <p>Anthracite</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-birch.webp" alt="Birch" />
                    <p>Birch</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-blush.webp" alt="Blush" />
                    <p>Blush</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-canvas.webp" alt="Canvas" />
                    <p>Canvas</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-chestnut.webp" alt="Chestnut" />
                    <p>Chestnut</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-dolphin.webp" alt="Dolphin" />
                    <p>Dolphin</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-elephant.webp" alt="Elephant" />
                    <p>Elephant</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-fern.webp" alt="Fern" />
                    <p>Fern</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-ocean.webp" alt="Ocean" />
                    <p>Ocean</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-pewter.webp" alt="Pewter" />
                    <p>Pewter</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-platinum.webp" alt="Platinum" />
                    <p>Platinum</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-Silver.webp" alt="Silver" />
                    <p>Silver</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/henley-slate.webp" alt="Slate" />
                    <p>Slate</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Henley