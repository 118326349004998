import { Route, Routes, BrowserRouter } from "react-router-dom";

import Navigation from "./components/Navigation";

import Home from "./pages/Home";
import Catalogue from "./pages/Catalogue";
import Contact from "./pages/Contact";

import Carpets from "./pages/Catalogue/Carpets";
import Furniture from "./pages/Catalogue/Furniture";
import Laminate from "./pages/Catalogue/Laminate";
import Vinyl from "./pages/Catalogue/Vinyl";
import Mosque from "./pages/Catalogue/Mosque";
import Other from "./pages/Catalogue/Other";

import Footer from "./components/Footer";

import Marlow from "./pages/Catalogue/Carpets/Marlow";
import Henley from "./pages/Catalogue/Carpets/Henley";
import Aztec from "./pages/Catalogue/Carpets/Aztec";
import Dublin from "./pages/Catalogue/Carpets/Dublin";
import Luxury from "./pages/Catalogue/Carpets/Luxury";
import Andes from "./pages/Catalogue/Carpets/Andes";

import Mood from "./pages/Catalogue/Laminate/Mood";
import Effect from "./pages/Catalogue/Laminate/Effect";
import Herringbone from "./pages/Catalogue/Laminate/Herringbone";

import Mattress from "./pages/Catalogue/Furniture/Mattress";
import Sofas from "./pages/Catalogue/Furniture/Sofas";
import Bedroom from "./pages/Catalogue/Furniture/Bedroom";

import Floortex from "./pages/Catalogue/Vinyl/Floortex";
import Verdi from "./pages/Catalogue/Vinyl/Verdi";
import Hightex from "./pages/Catalogue/Vinyl/Hightex";

import Underlay from "./pages/Catalogue/Other/Underlay";
import Grass from "./pages/Catalogue/Other/Grass";
import Rods from "./pages/Catalogue/Other/Rods";

import Emerald from "./pages/Catalogue/Mosque/Emerald";
import Diamond from "./pages/Catalogue/Mosque/Diamond";
import Madalion from "./pages/Catalogue/Mosque/Madalion";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='catalogue'>

            <Route index element={<Catalogue />} />

            <Route path='carpets'>
              <Route index element={<Carpets />}/>

              <Route path='marlow' element={<Marlow />} />
              <Route path='henley' element={<Henley />} />
              <Route path='aztec' element={<Aztec />} />
              <Route path='dublin-twist' element={<Dublin />} />
              <Route path='luxury' element={<Luxury />} />
              <Route path='andes' element={<Andes />} />
            </Route>

            <Route path='laminate'>
              <Route index element={<Laminate />}/>

              <Route path='mood' element={<Mood />} />
              <Route path='effect' element={<Effect />} />
              <Route path='herringbone' element={<Herringbone />} />
            </Route>

            <Route path='furniture'>
              <Route index element={<Furniture />}/>

              <Route path='mattress' element={<Mattress />} />
              <Route path='sofas' element={<Sofas />} />
              <Route path='bedroom' element={<Bedroom />} />
            </Route>

            <Route path='vinyl'>
              <Route index element={<Vinyl />}/>

              <Route path='floortex' element={<Floortex />} />
              <Route path='verdi' element={<Verdi />} />
              <Route path='hightex' element={<Hightex />} />
            </Route>

            <Route path='mosque'>
              <Route index element={<Mosque />}/>

              <Route path='emerald' element={<Emerald />} />
              <Route path='diamond' element={<Diamond />} />
              <Route path='madalion' element={<Madalion />} />
            </Route>

            <Route path='other'>
              <Route index element={<Other />}/>

              <Route path='underlay' element={<Underlay />} />
              <Route path='artificial-grass' element={<Grass />} />
              <Route path='stair-rods' element={<Rods />} />
            </Route>

          </Route>
          <Route path='contact' element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
