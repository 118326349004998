import React from 'react'

import { Link } from 'react-router-dom';

const Carpets = () => {
  return (
    <div className='catalogue'>
      <div className="container">
        <h1>Carpets</h1>
        <div className="items">
          <div className="categories">
            <Link to="/catalogue/carpets/marlow"><div className='category'>Marlow</div></Link>
            <Link to="/catalogue/carpets/henley"><div className='category'>Henley</div></Link>
            <Link to="/catalogue/carpets/aztec"><div className='category'>Aztec</div></Link>
            <Link to="/catalogue/carpets/dublin-twist"><div className='category'>Dublin Twist</div></Link>
            <Link to="/catalogue/carpets/luxury"><div className='category'>Luxury</div></Link>
            <Link to="/catalogue/carpets/andes"><div className='category'>Andes</div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carpets