import React from 'react'

const Emerald = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Emerald</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/mosque/beige-dots.png" alt="Dotted Beige" />
                    <p>Dotted Beige</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/cream-dots.png" alt="Dotted Cream" />
                    <p>Dotted Cream</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/green-dots.png" alt="Dotted Green" />
                    <p>Dotted Green</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/green-plain.png" alt="Plain Green" />
                    <p>Plain Green</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/navy-dots.png" alt="Dotted Navy" />
                    <p>Dotted Navy</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/red-dots.png" alt="Dotted Red" />
                    <p>Dotted Red</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/red-plain.png" alt="Plain Red" />
                    <p>Plain Red</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/teal-dots.png" alt="Dotted Teal" />
                    <p>Dotted Teal</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/teal-plain.png" alt="Plain Teal" />
                    <p>Plain Teal</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Emerald