import React from 'react'

import { Link } from 'react-router-dom';

const Mosque = () => {
  return (
    <div className='catalogue'>
      <div className="container">
        <h1>Mosque Carpets</h1>
        <div className="items">
          <div className="categories">
            <Link to="/catalogue/mosque/emerald"><div className='category'>Emerald</div></Link>
            <Link to="/catalogue/mosque/diamond"><div className='category'>Diamond</div></Link>
            <Link to="/catalogue/mosque/madalion"><div className='category'>Madalion</div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mosque