import React from 'react'

const Aztec = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Aztec</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3113.webp" alt="3113" />
                    <p>3113</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3115.webp" alt="3115" />
                    <p>3115</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3121.webp" alt="3121" />
                    <p>3121</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3122.webp" alt="3122" />
                    <p>3122</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3124.webp" alt="3124" />
                    <p>3124</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3126.webp" alt="3126" />
                    <p>3126</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3128.webp" alt="3128" />
                    <p>3128</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/aztec-3137.webp" alt="3137" />
                    <p>3137</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Aztec