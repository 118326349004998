import React from 'react'

const Herringbone = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Herringbone</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-bordeaux.webp" alt="Bordeaux Oak" />
                    <p>Bordeaux Oak</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-calais.webp" alt="Calais Oak" />
                    <p>Calais Oak</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-ferrara.webp" alt="Ferrara Oak" />
                    <p>Ferrara Oak</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-metz.webp" alt="Metz Oak" />
                    <p>Metz Oak</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-pisa.webp" alt="Pisa Oak" />
                    <p>Pisa Oak</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-toulouse.webp" alt="Toulouse Oak" />
                    <p>Toulouse Oak</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/herringbone-treviso.webp" alt="Treviso Oak" />
                    <p>Treviso Oak</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Herringbone