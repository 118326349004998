import React from 'react'

import { Link } from 'react-router-dom';

const Other = () => {
  return (
    <div className='catalogue'>
      <div className="container">
        <h1>Other</h1>
        <div className="items">
          <div className="categories">
            <Link to="/catalogue/other/underlay"><div className='category'>Underlay</div></Link>
            <Link to="/catalogue/other/artificial-grass"><div className='category'>Artificial Grass</div></Link>
            <Link to="/catalogue/other/stair-rods"><div className='category'>Stair Rods</div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Other