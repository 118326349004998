import React from 'react'

const Dublin = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Dublin Twist</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-107.webp" alt="107" />
                    <p>107</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-140.webp" alt="140" />
                    <p>140</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-151.webp" alt="151" />
                    <p>151</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-155.webp" alt="155" />
                    <p>155</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-156.webp" alt="156" />
                    <p>156</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-158.webp" alt="158" />
                    <p>158</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-303.webp" alt="303" />
                    <p>303</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-307.webp" alt="307" />
                    <p>307</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-314.webp" alt="314" />
                    <p>314</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-319.webp" alt="319" />
                    <p>319</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-406.webp" alt="406" />
                    <p>406</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-445.webp" alt="445" />
                    <p>445</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-882.webp" alt="882" />
                    <p>882</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-894.webp" alt="894" />
                    <p>894</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Dublin-Twist-897.webp" alt="897" />
                    <p>897</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dublin