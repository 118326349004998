import React from 'react'

const Hightex = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>High Tex</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-cornwall-642.jpg" alt="Cornwall 642" />
                    <p>Cornwall 642</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-devon-909.jpg" alt="Devon 909" />
                    <p>Devon 909</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-dianawood-997.jpg" alt="Diana Wood 997" />
                    <p>Diana Wood 997</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-fiona-099.jpg" alt="Fiona 099" />
                    <p>Fiona 099</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-keyline-906.jpg" alt="Keyline 906" />
                    <p>Keyline 906</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-keyline-990.jpg" alt="Keyline 990" />
                    <p>Keyline 990</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-leyton-109.jpg" alt="Leyton 109" />
                    <p>Leyton 109</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-leyton-260.jpg" alt="Leyton 260" />
                    <p>Leyton 260</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-leyton-609.jpg" alt="Leyton 609" />
                    <p>Leyton 609</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-leyton-629.jpg" alt="Leyton 629" />
                    <p>Leyton 629</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-softoak-098.jpg" alt="Soft Oak 098" />
                    <p>Soft Oak 098</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-softoak-890.jpg" alt="Soft Oak 890" />
                    <p>Soft Oak 890</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-trentino-009.jpg" alt="Trentino 009" />
                    <p>Trentino 009</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-trentino-907.jpg" alt="Trentino 907" />
                    <p>Trentino 907</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-trentino-994.jpg" alt="Trentino 994" />
                    <p>Trentino 994</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-warmoak-090.jpg" alt="Warm Oak 090" />
                    <p>Warm Oak 090</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-warmoak-666.jpg" alt="Warm Oak 666" />
                    <p>Warm Oak 666</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-warmoak-909.jpg" alt="Warm Oak 909" />
                    <p>Warm Oak 909</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-washedoak-970.jpg" alt="Washed Oak 970" />
                    <p>Washed Oak 970</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/hightex-washedoak-979.jpg" alt="Washed Oak 979" />
                    <p>Washed Oak 979</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hightex