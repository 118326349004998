import React from 'react'

const Effect = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Effect</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-alpine.webp" alt="Alpine" />
                    <p>Alpine</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-atlas.webp" alt="Atlas" />
                    <p>Atlas</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-atlay.webp" alt="Atlay" />
                    <p>Atlay</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-elbruz.webp" alt="Elbruz" />
                    <p>Elbruz</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-everest.webp" alt="Everest" />
                    <p>Everest</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-fuji.webp" alt="Fuji" />
                    <p>Fuji</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-logan.webp" alt="Logan" />
                    <p>Logan</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-nirvana.webp" alt="Nirvana" />
                    <p>Nirvana</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-pamir.webp" alt="Pamir" />
                    <p>Pamir</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-rosso.webp" alt="Rosso" />
                    <p>Rosso</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-solaro.webp" alt="Solaro" />
                    <p>Solaro</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-taurus.webp" alt="Taurus" />
                    <p>Taurus</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-tibet.webp" alt="Tibet" />
                    <p>Tibet</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/effect-ural.webp" alt="Ural" />
                    <p>Ural</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Effect