import React from 'react'

const Madalion = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Madalion</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-red.png" alt="Red Pillars" />
                    <p>Red Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-beige.png" alt="Beige Pillars" />
                    <p>Beige Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-brown.png" alt="Brown Pillars" />
                    <p>Brown Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-navy.png" alt="Navy Pillars" />
                    <p>Navy Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-olive.png" alt="Olive Pillars" />
                    <p>Olive Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-rose.png" alt="Rose Pillars" />
                    <p>Rose Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-modern-navy.png" alt="Modern Navy Pillars" />
                    <p>Modern Navy Pillars</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/pillars-silver.png" alt="Silver Pillars" />
                    <p>Silver Pillars</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Madalion