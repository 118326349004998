import React from 'react'

const Mattress = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Mattresses</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/furniture/3d-backcare-mattress.webp" alt="3d-backcare" />
                    <p>3D Backcare Mattress <br></br> <span>From £320<br></br>This best mattress for back pain, people with back issues or bad back can completely relay on 3D Back Care orthopaedic . Our back care mattress is designed to using hi-tech memory foam and reflex form, which play role in weight distribution and keep your spine aligned and supported in any position, providing ultimate comfort and relieving pressure points.</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/3d-backcare-mattress.webp" alt="7-zone-memory-foam" />
                    <p>7 Zone Memory Foam Mattress <br></br> <span>From £300<br></br>Experience ultimate comfort and support with our hi-tech memory foam and reflex foam combination for a perfect night's rest. Our hypoallergenic, anti-bacterial, and anti-dust mite mattress is designed for maximum comfort for those who need it most. With its elegant knitted fabric finished in micro quilt design, you're guaranteed a peaceful sleep</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/hybrid-reflex-mattress.webp" alt="hydrid-reflex" />
                    <p>Hybrid Reflex Mattress <br></br> <span>From £270<br></br>Incorporating a 1000 air suspension pocket sprung unit, with hi-tech memory foam and luxurious fillings. Premium stretch free fabric with temperature sensitive technology. Hypo-allergenic, anti-bacterial and anti-dust mite. The mattress is designed to provide superior levels of comfort, support and resilience for a restful nights sleep</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/majestic-9000-mattress.webp" alt="majestic-9000" />
                    <p>Majestic 9000 Mattress <br></br> <span>From £320<br></br>This majestic 9000 offers superior support and relieving comfort. With an elegant knitted fabric finished in a micro quilt design, this hypoallergenic & anti-dust mite mattress is perfect for anyone looking for the ultimate sleeping experience.</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/majestic-11000-mattress.webp" alt="majestic-11000" />
                    <p>Majestic 11000 Mattress <br></br> <span>From £320<br></br>With a luxurious, hi-tech foam that provides the ultimate support and relieving comfort, you'll enjoy a good night's sleep. This Majestic Mattress Featuring a knitted fabric finished with a micro quilt design, this mattress is perfect for any home. Plus, it's hypo-allergenic, anti-bacterial and anti-dust mite</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/natural-touch-mattress.webp" alt="natural-touch" />
                    <p>Natural Touch Mattress <br></br> <span>From £280<br></br>This mattress has high density reflex foam and hi-tech memory foam, providing ultimate support and relieving comfort. Elegant knitted fabric finished in micro quilt design. Hypo-allergenic, anti-bacterial and anti-dust mite.</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/reverso-memory-mattress.webp" alt="reverso-memory" />
                    <p>Reverso Memory Mattress <br></br> <span>From £300<br></br>This mattress has hi-tech memory foam and reflex foam, providing ultimate support and relieving comfort. Elegant knitted fabric finished in micro quilt design. Hypo-allergenic, anti-bacterial and anti-dust mite. The 2 inch memory sheet represents the best addition that will enhance the level of support and comfort.</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rock-1200-mattress.webp" alt="rock-1200" />
                    <p>Rock 1200 Mattress <br></br> <span>From £300<br></br>Rock hard mattress is designed extra firm mattress with high-density reflex foam and a micro-quilt design to provide you the support and comfort you need. Rock hard mattress is hypoallergenic, anti-bacterial, and anti-dust mite, so you can sleep soundly knowing your mattress is tailored to your individual needs.</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/silk-memory-mattress.webp" alt="silk-memory" />
                    <p>Silk Memory Mattress <br></br> <span>From £270<br></br>Its combination of hi-tech memory foam and reflex foam creates superior support, ensuring your body is properly aligned for a restful night's sleep. The micro-quilt fabric and hypo-allergenic, anti-bacterial, anti-dust mite design give an elegant and hygienic finish.</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/hotel-howden-mattress.png" alt="hotel-howden" />
                    <p>Hotel Howden Mattress <br></br> <span>Single £290, Double £360, King £400, Super King £490<br></br>Strong springs which work independently from one another to provide support to all parts of the body. Surrounding the springs are generous layers of luxury fillings to ensure a comfortable night's sleep with a firm comfort support. Finished in a high qualityCrib 5 Barrier Fabric and hand tufted for a supportive and comfortable sleep.</span></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mattress