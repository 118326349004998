import React from 'react'

import { Link } from 'react-router-dom';

const Vinyl = () => {
  return (
    <div className='catalogue'>
      <div className="container">
        <h1>Vinyl</h1>
        <div className="items">
          <div className="categories">
            <Link to="/catalogue/vinyl/floortex"><div className='category'>Floortex</div></Link>
            <Link to="/catalogue/vinyl/verdi"><div className='category'>Verdi</div></Link>
            <Link to="/catalogue/vinyl/hightex"><div className='category'>High Tex</div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vinyl