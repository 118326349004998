import React from 'react'

const Bedroom = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Bedroom Furniture</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/furniture/alexandra-oak.png" alt="Alexandra Oak" />
                    <p>Alexandra Oak Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/half-moon-twin-cream.png" alt="Half Moon Twin Cream" />
                    <p>Half Moon Twin Cream Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/half-moon-twin-charcoal.png" alt="Half Moon Twin Charcoal" />
                    <p>Half Moon Twin Charcoal Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/model-001-walnut.png" alt="Model 001 Walnut" />
                    <p>Model 001 Walnut</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/romeo.png" alt="Romeo" />
                    <p>Romeo Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rugby-black-led.png" alt="Rugby Black LED" />
                    <p>Rugby Black Bed with LED</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rugby-white-led.png" alt="Rugby White LED" />
                    <p>Rugby White Bed with LED</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rugby-glossy-twin-cream.png" alt="Rugby Glossy Twin Cream" />
                    <p>Rugby Glossy Twin Cream Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rugby-glossy-walnut.png" alt="Rugby Glossy Walnut" />
                    <p>Rugby Glossy Walnut Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/slim-led.png" alt="Slim LED" />
                    <p>Slim Bed with LED</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rugby-white.png" alt="Rugby White" />
                    <p>Rugby White Bed</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rugby-black.png" alt="Rugby Black" />
                    <p>Rugby Black Bed</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Bedroom 