import React from 'react'

const Marlow = () => {
  return (
        <div className="range">
            <div className="container">
                <h1>Marlow</h1>
                <div className="items">
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-corn.webp" alt="Corn" />
                        <p>Corn</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-anthracite.webp" alt="Anthracite" />
                        <p>Anthracite</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-birch.webp" alt="Birch" />
                        <p>Birch</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-blush.webp" alt="Blush" />
                        <p>Blush</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-canvas.webp" alt="Canvas" />
                        <p>Canvas</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-chestnut.webp" alt="Chestnut" />
                        <p>Chestnut</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-dolphin.webp" alt="Dolphin" />
                        <p>Dolphin</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-elephant.webp" alt="Elephant" />
                        <p>Elephant</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-fern.webp" alt="Fern" />
                        <p>Fern</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-ocean.webp" alt="Ocean" />
                        <p>Ocean</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-pewter.webp" alt="Pewter" />
                        <p>Pewter</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-platinum.webp" alt="Platinum" />
                        <p>Platinum</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-Silver.webp" alt="Silver" />
                        <p>Silver</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/marlow-slate.webp" alt="Slate" />
                        <p>Slate</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marlow