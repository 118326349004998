import React from 'react'

const Rods = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Stair Rods</h1>
            <br></br>

            <h2>Balmoral</h2>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-antique-brass.jpeg" alt="Antique Brass" />
                    <p>Antique Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-black.jpeg" alt="Black" />
                    <p>Black</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-brass.jpeg" alt="Brass" />
                    <p>Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-bronze.jpeg" alt="Bronze" />
                    <p>Bronze</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-brushed-chrome.jpeg" alt="Brushed Chrome" />
                    <p>Brushed Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-chrome.jpeg" alt="Chrome" />
                    <p>Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-pewter.jpeg" alt="Pewter" />
                    <p>Pewter</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-polished-nickel.jpeg" alt="Polished Nickel" />
                    <p>Polished Nickel</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-satin-brass.jpeg" alt="Satin Brass" />
                    <p>Satin Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/balmoral-satin-nickel.jpeg" alt="Satin Nickel" />
                    <p>Satin Nickel</p>
                </div>
            </div>
            
            <h2>Chatsworth</h2>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-antique-brass.jpeg" alt="Antique Brass" />
                    <p>Antique Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-black.jpeg" alt="Black" />
                    <p>Black</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-brass.jpeg" alt="Brass" />
                    <p>Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-bronze.jpeg" alt="Bronze" />
                    <p>Bronze</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-brushed-chrome.jpeg" alt="Brushed Chrome" />
                    <p>Brushed Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-chrome.jpeg" alt="Chrome" />
                    <p>Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-pewter.jpeg" alt="Pewter" />
                    <p>Pewter</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-polished-nickel.jpeg" alt="Polished Nickel" />
                    <p>Polished Nickel</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-satin-brass.jpeg" alt="Satin Brass" />
                    <p>Satin Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/chatsworth-satin-nickel.jpeg" alt="Satin Nickel" />
                    <p>Satin Nickel</p>
                </div>
            </div>

            <h2>Woburn</h2>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/woburn-antique-brass.jpeg" alt="Antique Brass" />
                    <p>Antique Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-black.jpeg" alt="Black" />
                    <p>Black</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-brass.jpeg" alt="Brass" />
                    <p>Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-bronze.jpeg" alt="Bronze" />
                    <p>Bronze</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-brushed-chrome.jpeg" alt="Brushed Chrome" />
                    <p>Brushed Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-chrome.jpeg" alt="Chrome" />
                    <p>Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-pewter.jpeg" alt="Pewter" />
                    <p>Pewter</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-polished-nickel.jpeg" alt="Polished Nickel" />
                    <p>Polished Nickel</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-satin-brass.jpeg" alt="Satin Brass" />
                    <p>Satin Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/woburn-satin-nickel.jpeg" alt="Satin Nickel" />
                    <p>Satin Nickel</p>
                </div>
            </div>

            <h2>Lancaster</h2>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-antique-brass.jpeg" alt="Antique Brass" />
                    <p>Antique Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-black.jpeg" alt="Black" />
                    <p>Black</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-brass.jpeg" alt="Brass" />
                    <p>Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-bronze.jpeg" alt="Bronze" />
                    <p>Bronze</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-brushed-chrome.jpeg" alt="Brushed Chrome" />
                    <p>Brushed Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-chrome.jpeg" alt="Chrome" />
                    <p>Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-pewter.jpeg" alt="Pewter" />
                    <p>Pewter</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-polished-nickel.jpeg" alt="Polished Nickel" />
                    <p>Polished Nickel</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-satin-brass.jpeg" alt="Satin Brass" />
                    <p>Satin Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/lancaster-satin-nickel.jpeg" alt="Satin Nickel" />
                    <p>Satin Nickel</p>
                </div>
            </div>

            <h2>Windsor</h2>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/windsor-antique-brass.jpeg" alt="Antique Brass" />
                    <p>Antique Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-black.jpeg" alt="Black" />
                    <p>Black</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-brass.jpeg" alt="Brass" />
                    <p>Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-bronze.jpeg" alt="Bronze" />
                    <p>Bronze</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-brushed-chrome.jpeg" alt="Brushed Chrome" />
                    <p>Brushed Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-chrome.jpeg" alt="Chrome" />
                    <p>Chrome</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-pewter.jpeg" alt="Pewter" />
                    <p>Pewter</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-polished-nickel.jpeg" alt="Polished Nickel" />
                    <p>Polished Nickel</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-satin-brass.jpeg" alt="Satin Brass" />
                    <p>Satin Brass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/windsor-satin-nickel.jpeg" alt="Satin Nickel" />
                    <p>Satin Nickel</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Rods