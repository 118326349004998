import React from 'react'

const Grass = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Artificial Grass</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/cadiz-grass.jpeg" alt="Cadiz Grass" />
                    <p>Cadiz Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/cordoba-grass.jpeg" alt="Cordoba Grass" />
                    <p>Cordoba Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/durban-grass.jpeg" alt="Durban Grass" />
                    <p>Durban Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/granada-grass.jpeg" alt="Granada Grass" />
                    <p>Granada Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/london-grass.jpeg" alt="London Grass" />
                    <p>London Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/malaga-grass.jpeg" alt="Malaga Grass" />
                    <p>Malaga Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/natural-look-grass.jpeg" alt="Natural Look Grass" />
                    <p>Natural Look Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/premiership-grass.jpeg" alt="Premiership Grass" />
                    <p>Premiership Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/rio-grass.jpeg" alt="Rio Grass" />
                    <p>Rio Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/rio-plus-grass.jpeg" alt="Rio Plus Grass" />
                    <p>Rio Plus Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/seville-grass.jpeg" alt="Seville Grass" />
                    <p>Seville Grass</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/york-grass.jpeg" alt="York Grass" />
                    <p>York Grass</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Grass