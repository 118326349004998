import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div className="container">
            <p>MCFHOME LTD © 2015 - { new Date().getFullYear() }</p>
            <a href="https://mdxyasir.com" target="_blank" rel='noreferrer'>Website by mdxyasir.</a>
        </div>
    </footer>
  )
}

export default Footer