import React from 'react'

const Contact = () => {
  return (
    <div className="contact">
        <div className="container">
            <h1>Contact Us</h1>
            <div className="grid">
                <div>
                    <div className="details">
                        <p>146 Martha Street <br /> Shadwell, London <br /> E1 2PG</p>
                        <br />
                        <p><i className='fas fa-envelope' /> info@mcfhome.co.uk</p>
                    </div>
                    <br />
                    <div className='external'>
                        <a href="tel:02077900202" target='_blank' rel="noreferrer"><button><i className='fas fa-phone' /> 0207 790 0202</button></a>
                        <a href="https://wa.me/442077900202" target='_blank' rel="noreferrer"><button><i className='fab fa-whatsapp' />WhatsApp</button></a>
                        <a href="https://review.mcfhome.co.uk" target='_blank' rel="noreferrer"><button>Review Us</button></a>
                    </div>
                </div>
                <div>
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19860.44413604557!2d-0.06614183929759136!3d51.52137009622896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876032dac74a2bd%3A0xe8f79c0d7929087b!2sMedina%20Carpets%20%26%20Furniture!5e0!3m2!1sen!2suk!4v1693219072384!5m2!1sen!2suk" style={{ border:0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact