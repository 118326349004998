import React from 'react'

const Diamond = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Diamond</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-brown.png" alt="Diamond Brown" />
                    <p>Diamond Brown</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-burgundy.png" alt="Diamond Burgundy" />
                    <p>Diamond Burgundy</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-green.png" alt="Diamond Green" />
                    <p>Diamond Green</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-navy.png" alt="Diamond Navy" />
                    <p>Diamond Navy</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-ocean.png" alt="Diamond Ocean" />
                    <p>Diamond Ocean</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-olive.png" alt="Diamond Olive" />
                    <p>Diamond Olive</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-red.png" alt="Diamond Red" />
                    <p>Diamond Red</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-silver.png" alt="Diamond Silver" />
                    <p>Diamond Silver</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/mosque/diamond-slate.png" alt="Diamond Slate" />
                    <p>Diamond Slate</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Diamond