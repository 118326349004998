import React from 'react'

const Andes = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Andes</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6311.webp" alt="6311" />
                    <p>6311</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6312.webp" alt="6312" />
                    <p>6312</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6316.webp" alt="6316" />
                    <p>6316</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6318.webp" alt="6318" />
                    <p>6318</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6321.webp" alt="6321" />
                    <p>6321</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6323.webp" alt="6323" />
                    <p>6232</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6325.webp" alt="6325" />
                    <p>6325</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/carpets/Andes-6328.webp" alt="6328" />
                    <p>6328</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Andes