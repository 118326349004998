import React from 'react'

import { Link } from 'react-router-dom';

const Furniture = () => {
  return (
    <div className='catalogue'>
      <div className="container">
        <h1>Furniture</h1>
        <div className="items">
          <div className="categories">
            <Link to="/catalogue/furniture/mattress"><div className='category'>Mattress</div></Link>
            <Link to="/catalogue/furniture/sofas"><div className='category'>Sofas</div></Link>
            <Link to="/catalogue/furniture/bedroom"><div className='category'>Bedroom</div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Furniture