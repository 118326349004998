import React from 'react'

const Luxury = () => {
    return (
        <div className="range">
            <div className="container">
                <h1>Luxury</h1>
                <div className="items">
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Aubergine.jpg" alt="Aubergine" />
                        <p>Aubergine</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Beige.jpg" alt="Beige" />
                        <p>Beige</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Cappuccino.jpg" alt="Cappuccino" />
                        <p>Cappuccino</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Caramel.jpg" alt="Caramel" />
                        <p>Caramel</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Cobalt.jpg" alt="Cobalt" />
                        <p>Cobalt</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Dark-Grey.jpg" alt="Dark Grey" />
                        <p>Dark Grey</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Frost-Silver.jpg" alt="Frost Silver" />
                        <p>Frost Silver</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Grey.jpg" alt="Grey" />
                        <p>Grey</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Ivory.jpg" alt="Ivory" />
                        <p>Ivory</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Light-Brown.jpg" alt="Light Brown" />
                        <p>Light Brown</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Malt.jpg" alt="Malt" />
                        <p>Malt</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Mid-Grey.jpg" alt="Mid Grey" />
                        <p>Mid Grey</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Nutmeg.jpg" alt="Nutmeg" />
                        <p>Nutmeg</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Oak.jpg" alt="Oak" />
                        <p>Oak</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Red.jpg" alt="Red" />
                        <p>Red</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Rich-Oak.jpg" alt="Rich Oak" />
                        <p>Rich Oak</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Sandstone.jpg" alt="Sandstone" />
                        <p>Sandstone</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Sapphire.jpg" alt="Sapphire" />
                        <p>Sapphire</p>
                    </div>
                    <div>
                        <img src="../../../../media/catalogue/carpets/Deep-Feelings-Silver.jpg" alt="Silver" />
                        <p>Silver</p>
                    </div>
                </div>
            </div>
        </div>
      )
}

export default Luxury