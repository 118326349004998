import React from 'react'

import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className="home">
        <div className="container">
            <h1>Here to make you feel at <span>home</span></h1>
            <p className='description'>Redesigning comfort; luxury carpets and exquisite <br /> furniture by Medina Carpets & Furniture.</p>
            <Link to='catalogue'><button>View Catalogue</button></Link>
            <div className="social">
                <Link to="https://www.facebook.com/medinacarpets/" target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                </Link>
                <Link to="https://www.instagram.com/medinacarpets/" target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram"></i>
                </Link>
                <Link to="https://www.tiktok.com/@medinacpt" target="_blank" rel="noreferrer">
                    <i className="fab fa-tiktok"></i>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Home