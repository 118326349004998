import React from 'react'

const Verdi = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Verdi</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-alaska-536.jpg" alt="Alaska 536" />
                    <p>Alaska 536</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-alaska-594.jpg" alt="Alaska 594" />
                    <p>Alaska 594</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-barcelona-512.jpg" alt="Barcelona 512" />
                    <p>Barcelona 512</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-barcelona-570.jpg" alt="Barcelona 570" />
                    <p>Barcelona 570</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-evora-513.jpg" alt="Evora 513" />
                    <p>Evora 513</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-evora-592.jpg" alt="Evora 592" />
                    <p>Evora 592</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-helsinki-536.jpg" alt="Helsinki 536" />
                    <p>Helsinki 536</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-helsinki-582.jpg" alt="Helsinki 582" />
                    <p>Helsinki 582</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-helsinki-592.jpg" alt="Helsinki 592" />
                    <p>Helsinki 592</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-helsinki-598.jpg" alt="Helsinki-598" />
                    <p>Helsinki 598</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-modica-532.jpg" alt="Modica 532" />
                    <p>Modica 532</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-modica-595.jpg" alt="Modica 595" />
                    <p>Modica 595</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-nimes-532.jpg" alt="Nimes 532" />
                    <p>Nimes 532</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-nimes-536.jpg" alt="Nimes 536" />
                    <p>Nimes 536</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-nimes-588.jpg" alt="Nimes 588" />
                    <p>Nimes 588</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-nimes-592.jpg" alt="Nimes 592" />
                    <p>Nimes 592</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/vinyl/verdi-pinnacles-573.jpg" alt="Pinnacles 573" />
                    <p>Pinnacles 573</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Verdi