import React from 'react'

const Sofas = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Sofas</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/furniture/belfast-sofa.jpg" alt="Belfast Sofa" />
                    <p>Belfast Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/cardiff-curved-arm-sofa.jpg" alt="Cardiff Curved Arm Sofa" />
                    <p>Cardiff Curved Arm Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/coventry-club-sofa.jpg" alt="Conventry Club Sofa" />
                    <p>Coventry Club Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/derby-club-sofa.jpg" alt="Derby Club Sofa" />
                    <p>Derby Club Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/edinburgh-scattered-sofa.jpg" alt="Edinburgh Scattered Sofa" />
                    <p>Edinburgh Scattered Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/leeds-sofa.jpg" alt="Leeds Sofa" />
                    <p>Leeds Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/liverpool-chesterfield-sofa.jpg" alt="Liverpool Chesterfield Sofa" />
                    <p>Liverpool Chesterfield Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/norwich-sofa.jpg" alt="Norwich Sofa" />
                    <p>Norwich Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/nottingham-sofa.jpg" alt="Nottingham Sofa" />
                    <p>Nottingham Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/peterborough-sofa.jpg" alt="Peterborough Sofa" />
                    <p>Peterborough Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/preston-sofa.jpg" alt="Preston Sofa" />
                    <p>Preston Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/salford-sofa.jpg" alt="Salford Sofa" />
                    <p>Salford Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/salisbury-sofa.jpg" alt="Salisbury Sofa" />
                    <p>Salisbury Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/sheffield-classic-sofa.jpg" alt="Sheffield Classic Sofa" />
                    <p>Sheffield Classic Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/sheffield-corner-sofa.jpg" alt="Sheffield Corner Sofa" />
                    <p>Sheffield Corner Sofa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/sunderland-sofa.jpg" alt="Sunderland Sofa" />
                    <p>Sunderland Sofa</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sofas