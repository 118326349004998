import React from 'react'

const Underlay = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Underlay</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/other/5mm-timbertech-laminate-underlay.jpg" alt="5mm Timbertech Laminate Underlay" />
                    <p>5mm Timbertech Laminate</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/5mm-xps-foam-laminate-underlay.jpg" alt="5mm XPS Foam Laminate Underlay" />
                    <p>5mm Timbertech Laminate</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/comfy-deluxe-underlay.jpg" alt="Comfy Deluxe Underlay" />
                    <p>Comfy Deluxe Underlay</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/other/comfy-lux-underlay.jpg" alt="Comfy Lux Underlay" />
                    <p>Comfy Lux Underlay</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Underlay