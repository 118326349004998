import React from 'react'

import { Link } from 'react-router-dom'

const Navigation = () => {
  return (
    <header>
        <div className="container">
            <img src={process.env.PUBLIC_URL + "/media/logo-colour.png"} alt="logo"/>
            <nav>
                <Link to='/'>Home</Link>
                <Link to='catalogue'>Catalogue</Link>
                <Link to='contact'>Contact</Link>
            </nav>
        </div>
    </header>
  )
}

export default Navigation