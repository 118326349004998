import React from 'react'

const Mood = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Mood</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/laminate/mood-malta.webp" alt="Malta" />
                    <p>Malta</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/mood-mappa.webp" alt="Mappa" />
                    <p>Mappa</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/mood-mare.webp" alt="Mare" />
                    <p>Mare</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/mood-marine.webp" alt="Marine" />
                    <p>Marine</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/mood-marvel.webp" alt="Marvel" />
                    <p>Marvel</p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/laminate/mood-modo.webp" alt="Modo" />
                    <p>Modo</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mood